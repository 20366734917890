.promocode-bulk-status {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  font-size: var(--typography-font-size-14);
  font-family: var(--typography-font-family-arial);

  & .promocode-bulk-status__bold {
    font-weight: var(--typography-font-weight-bold);
  }
}
