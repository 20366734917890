.header {
  padding-top: 0.8rem;
  padding-right: 2.55rem;
  padding-bottom: 0.42rem;
  padding-left: 2.55rem;
  background-color: var(--color-dark-100);

  & .header__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-light-100);

    & .header__logo {
      width: 14rem;
    }

    & .header__name {
      padding-left: 1.55rem;
      border-left: 0.06rem solid var(--color-light-100);
    }
  }
}

.header__logo > .header__svg {
  width: 9.17rem;
  height: 2.285rem;
}
