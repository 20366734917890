:root {
  /* postcss-bem-linter: ignore */
  --r4: calc((4 / var(--base) * 1rem));
  /* postcss-bem-linter: ignore */
  --r8: calc((8 / var(--base) * 1rem));
  /* postcss-bem-linter: ignore */
  --r14: calc((14 / var(--base) * 1rem));
  /* postcss-bem-linter: ignore */
  --r20: calc((20 / var(--base) * 1rem));
  /* postcss-bem-linter: ignore */
  --r36: calc((36 / var(--base) * 1rem));
  /* postcss-bem-linter: ignore */
  --base: 16;
}
