.login {
  display: flex;
  justify-content: center;
  min-width: 100vw;
  margin-top: 10.125rem;
}

.login__container {
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.login__cover {
  position: relative;
  display: flex;
}

.login__cover-image {
  object-fit: cover;
}

.login__cover-text {
  position: absolute;
  top: 3rem;
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
}

.login__credentials {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
}

.login__text-title {
  margin-bottom: 1rem;
}

.login__credentials-text {
  margin-bottom: 2rem;
}

.input--error {
  margin-bottom: var(--r8);
}

.login__button {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.login__button .button {
  height: var(--r36);
  margin-top: var(--r20);
}

.login__input {
  flex: 1;
}

.login__input .input {
  width: 100%;
}
