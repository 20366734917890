.app {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 480px;
  margin: 0 auto;
  padding: 40px;
  color: #333;
}

.app .todo-list-counter {
  margin: 10px;
}

.app .notifications {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.app .notifications .notification {
  max-width: 480px;
}
