.flex {
  display: flex;
}

/* Flex direction */
.flex-direction-row {
  flex-direction: row;
}

.flex-direction-row-rev {
  flex-direction: row-reverse;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-column-rev {
  flex-direction: column-reverse;
}

/*  Flex justify content = */
.flex-justify-content-start {
  justify-content: flex-start;
}
.flex-justify-content-end {
  justify-content: flex-end;
}
.flex-justify-content-center {
  justify-content: center;
}

/* Flex align items */
.flex-align-items-default {
  align-items: stretch;
}
.flex-align-items-start {
  align-items: flex-start;
}
.flex-align-items-end {
  align-items: flex-end;
}
.flex-align-items-center {
  align-items: center;
}

/* FlexAlignContent */
.flex-align-content-default {
  align-content: stretch;
}
.flex-align-content-start {
  align-content: flex-start;
}
.flex-align-content-end {
  align-content: flex-end;
}
.flex-align-content-center {
  align-content: center;
}
.flex-align-content-between {
  align-content: space-between;
}
.flex-align-content-around {
  align-content: space-around;
}

/* Spacing */
/* stylelint-disable  */
.flex-direction-column.spacing-xxs > :not(:last-child),
.flex-direction-column-rev.spacing-xxs > :not(:last-child) {
  margin-bottom: 1rem;
}
.flex-direction-row.spacing-xxs > :not(:last-child),
.flex-direction-row-rev.spacing-xxs > :not(:last-child) {
  margin-right: 1rem;
}

.flex-direction-column.spacing-xs > :not(:last-child),
.flex-direction-column-rev.spacing-xs > :not(:last-child) {
  margin-bottom: 1.5rem;
}
.flex-direction-row.spacing-xs > :not(:last-child),
.flex-direction-row-rev.spacing-xs > :not(:last-child) {
  margin-right: 1.5rem;
}

.flex-direction-column.spacing-s > :not(:last-child),
.flex-direction-column-rev.spacing-s > :not(:last-child) {
  margin-bottom: 2rem;
}
.flex-direction-row.spacing-s > :not(:last-child),
.flex-direction-row-rev.spacing-s > :not(:last-child) {
  margin-right: 2rem;
}

.flex-direction-column.spacing-m > :not(:last-child),
.flex-direction-column-rev.spacing-m > :not(:last-child) {
  margin-bottom: 3rem;
}
.flex-direction-row.spacing-m > :not(:last-child),
.flex-direction-row-rev.spacing-m > :not(:last-child) {
  margin-right: 3rem;
}

.flex-direction-column.spacing-l > :not(:last-child),
.flex-direction-column-rev.spacing-l > :not(:last-child) {
  margin-bottom: 4rem;
}
.flex-direction-row.spacing-l > :not(:last-child),
.flex-direction-row-rev.spacing-l > :not(:last-child) {
  margin-right: 4rem;
}

.flex-direction-column.spacing-xl > :not(:last-child),
.flex-direction-column-rev.spacing-xl > :not(:last-child) {
  margin-bottom: 5rem;
}
.flex-direction-row.spacing-xl > :not(:last-child),
.flex-direction-row-rev.spacing-xl > :not(:last-child) {
  margin-right: 5rem;
}

.flex-direction-column.spacing-xxl > :not(:last-child),
.flex-direction-column-rev.spacing-xxl > :not(:last-child) {
  margin-bottom: 6rem;
}
.flex-direction-row.spacing-xxl > :not(:last-child),
.flex-direction-row-rev.spacing-xxl > :not(:last-child) {
  margin-right: 6rem;
}

.flex-direction-column.spacing-xxxl > :not(:last-child),
.flex-direction-column-rev.spacing-xxxl > :not(:last-child) {
  margin-bottom: 7.5rem;
}
.flex-direction-row.spacing-xxxl > :not(:last-child),
.flex-direction-row-rev.spacing-xxxl > :not(:last-child) {
  margin-right: 7.5rem;
}

.flex-direction-column.spacing-none > :not(:last-child),
.flex-direction-column-rev.spacing-none > :not(:last-child) {
  margin-bottom: none;
}
.flex-direction-row.spacing-none > :not(:last-child),
.flex-direction-row-rev.spacing-none > :not(:last-child) {
  margin-right: none;
}
/* stylelint-enable  */
