.form-control {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.1;
}

.form-control > :last-child {
  margin-right: 0.75em;
}

.radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  margin: 0.25em;
  color: #cecfd2;
  font: inherit;
  background-color: #fff;
  border: 0.07em solid #cecfd2;
  border-radius: 50%;
  appearance: none;
}

.radio:hover {
  border: 0.07em solid #9e9fa4;
}

.radio:focus {
  border: 0.14em solid #86bbd8 !important;
  outline: none;
}

.radio:disabled {
  background: #f3f3f3;
  border: 0.07em solid #e6e7e8;
}

.radio:checked {
  border: 0.07em solid #86bbd8;
}

.radio::before {
  width: 0.5em;
  height: 0.5em;
  background-color: CanvasText;
  border-radius: 50%;
  box-shadow: inset 1em 1em #86bbd8;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  content: "";
}

.radio:checked::before {
  transform: scale(1);
}
