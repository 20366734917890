/* stylelint-disable plugin/selector-bem-pattern */
/* TODO */
.side-menu {
  display: flex;
  flex: 0 0 16.55rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-dark-100);
}

.side-menu__item {
  display: flex;
  align-items: center;
  padding: 1.125rem;
  color: var(--color-dark-40);

  &:hover {
    cursor: pointer;
  }

  &.active {
    color: var(--color-light-100);
    background-color: var(--color-dark-80);
  }
}

.side-menu__icon {
  margin-right: 1.25rem;

  & path {
    fill: currentColor;
    stroke: none;
  }

  &.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  &.no-fill {
    & path {
      fill: none;
      stroke: currentColor;
    }
  }
}
