.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  & .main-layout__wrapper {
    display: flex;
    height: calc(100vh - 60px);
    & .main-layout__views {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 2rem;
      overflow: auto;
    }
  }
}
