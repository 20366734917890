.promocode-form {
  display: flex;
  flex-direction: column;

  & .promocode-form__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  & .promocode-form__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 3rem 5.5rem;
    background-color: #fff;
    & > :first-child {
      margin-bottom: 1.5rem;
    }
  }

  & .promocode-form__form {
    display: flex;
    flex: 1;
    & .input {
      width: 100%;
    }
  }

  & .promocode-form__column {
    flex: 1;
    & > :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  & .promocode-form__divider {
    margin: 0 5.5rem;
  }

  & .promocode-form__options {
    display: flex;
    flex-direction: column;
    & .checkbox:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  & .promocode-form__group {
    display: flex;
    justify-content: space-between;

    & > .field {
      width: calc(50% - 0.5rem);
    }
  }
  & .promocode-form__full-date-picker {
    width: 100%;
    & .date-picker {
      width: 100%;
    }
  }
}
