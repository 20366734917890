.promocode-list__content {
  display: flex;
  height: 100%;

  padding: 2rem;
  text-align: center;
  background-color: white;
  border: 1px solid none;
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0px var(--color-dark-30);

  & .promocode-list__section {
    flex-grow: 1;
  }
}
