.empty-promocodes-search__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  text-align: center;
  background-color: white;
  border: 1px solid none;
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0px var(--color-dark-30);

  & .empty-promocodes-search__section {
    max-width: 500px;
  }

  & .empty-promocodes-search__icon {
    margin-bottom: 1rem;

    & .icon__detail {
      width: 3rem;
      height: 3rem;
      color: gray;
    }
  }

  & .empty-promocodes-search__title {
    color: var(--color-dark-60);
  }

  & .empty-promocodes-search__body {
    color: var(--color-dark-60);
  }
}
