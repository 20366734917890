.toast__stack {
  position: fixed;
  top: 16px;
  right: 32px;
}

.toast__stack .stack__item {
  width: 600px;
  height: 72px;
}
