/* stylelint-disable */
.table {
  --table-header-text-color: var(--color-dark-100);
  --table-border-radius: var(--dimension-1-2-unit);
  --body-text-color: var(--color-dark-60);
  --border-color: var(--color-dark-20);
  --row-padding: var(--dimension-2-unit);

  background: white;
  border-bottom: 1px solid var(--border-color);
  border-radius: var(--table-border-radius);
}

.table > *:last-child {
  border-bottom: none;
}
/* stylelint-enable */

.table__head,
.table__body {
  font-family: var(--typography-font-family-arial);
  border-bottom: 1px solid var(--border-color);
}

.table__head {
  color: var(-table-header-text-color);
  font-weight: var(--typography-font-weight-bold);
}

.table__body {
  color: var(--body-text-color);
}

.table__head > .table__row > .table__cell {
  text-align: left;
}

.table__row:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.table__row--clickable {
  cursor: pointer;
}

.table__cell {
  padding: var(--row-padding);
}
