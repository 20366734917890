.promocodes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promocodes {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.export-promocodes {
  min-width: 7rem;
}

.promocodes-buttons {
  height: 40px;
}

.export-promocodes .export-promocodes__icon {
  margin-right: 0.5rem;
}

.export-promocodes .loader {
  width: 1.5rem;
  height: 1.5rem;
}

.export-promocodes .loader circle {
  stroke-width: calc(100 / 24);
  stroke: var(--color-dark-100);
}

.promocodes__list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  border-radius: 3px 3px 0px 0px;
}

.promocodes__list--empty {
  height: 100%;
}

.promocodes__search {
  margin: 1rem;
}

.promocodes__search .input {
  width: 100%;
}

.promocodes > :not(:last-child) {
  margin-bottom: 2rem;
}
