.pagination {
  /* stylelint-disable */
  --last-finsh-icon-size: var(--dimension-2-unit);
  --button-size: var(--dimension-5-unit);
  --padding-vertial: var(--dimension-unit);
  --padding-horizontal: var(--dimension-2-unit);
  --info-color: var(--color-dark-60);
  --disabled-page-color: var(--color-dark-100);
  --button-border-color: var(--color-dark-20);
  --button-disabled-border-color: var(--color-dark-10);
  --button-background: var(--color-dark-05);
  /* stylelint-enable */

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-vertial) var(--padding-horizontal);
  color: var(--info-color);
  font-family: var(--typography-font-weight-bold);
}

.pagination__info {
  color: var(--info-color);
}

.pagination__group {
  display: flex;
  align-items: center;
}

.pagination__page {
  width: var(--dimension-5-unit);
  height: var(--dimension-5-unit);
  font-size: var(--page-size);
  border: 1px solid var(--button-border-color);
}

.pagination .pagination__group .pagination__page:not(:last-child):not(:first-child) {
  border-right: none;
  border-radius: 0;
}
.pagination .pagination__group .pagination__page:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination .pagination__group .pagination__page:first-child {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination__page--noaction {
  pointer-events: none;
}

.pagination__page--active {
  background: var(--button-background);
  pointer-events: none;
}

.pagination__page--icon {
  width: var(--last-finsh-icon-size);
  height: var(--last-finsh-icon-size);
}

.pagination__page:disabled {
  color: var(--disabled-page-color);
  background: var(--button-background);
  border-color: var(--button-disabled-border-color);
}
